@import url('https://fonts.googleapis.com/css?family=Monoton');
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Outlined');
@import url('https://fonts.googleapis.com/icon?family=Material+Symbols+Outlined');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

html, body {
  /* IE 10-11 didn't like using min-height */
  height: 100%;
  /*
  height: 100vh;
  height: -webkit-fill-available;
  min-height: 100vh;*/
  -webkit-font-smoothing: antialiased;
  --ttblue: #005EB8;
  --ttgold: #DA9100;
  --ttpurple: #d3d6e9;
  --theme-primary-blue: #005EB8;
  --theme-secondary-dark: rgb(43, 43, 48);
  --theme-secondary-light: lightgrey;
  --font-primary-dark: rgba(30, 30, 34, 0.93);
  --font-secondary-light: rgb(249, 250, 251);
  --theme-box-shadow-light: 0px 0px 12px 2px #ebecf480;
  --theme-box-shadow-dark: 0px 2px 24px 0px #00000026;

}

i {
  cursor: pointer;
  padding: 10px;
}

h1 {
  cursor: pointer;
}

.firebase-emulator-warning {
  display: none;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-size: 16px;
  font-family: Roboto; 
  background-color: #f4f4f8;
   /* justify-content: center; Optionally center content horizontally */
}

.split-screen {
  display: flex;
}

.half {
  display: flex;
  width: 50%;
}

.third {
  width: 30%;
}

.forty {
  width: 33%;
  margin-left: 10px;
}

.fifth {
  width: 20%;
}

.seventh {
  width: 10%;
}

.eighty {
  width: 80%;
}

.cont-row-wrap {
  display: flex;
  flex-wrap: wrap;
}

.cont-row {
  display: flex;
}

.cont-col {
  display: flex;
  flex-direction: column;
}

.card {
  padding: 10px;
  border: 1px solid lightgrey;
  margin: 5px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: var(--font-primary-dark);
}

.card-warning {
  border: 1px solid orange;
  margin: 5px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: var(--font-primary-dark);
}

.card-edit {
  padding: 10px;
  border: 1px solid lightgrey;
  margin: 5px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: var(--font-primary-dark);
}

.card-edit:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.card-inner {
  flex: 1;
  overflow-y: auto; /* Enable vertical scroll */
}

.modal {
  max-height: 65vh !important;
}

.link {
  cursor: pointer;
  color: var(--ttblue);
}

.blue {
  color: var(--ttblue)!important;
}

.red {
  color: red !important;
}

.green {
  color: green !important;
}

.navy {
  color: navy !important;
}

.bg-lavender {
  background-color: lavender !important;
}

.bg-pink {
  background-color: pink !important;
}

.slider-blue {
  color: #2196F3;
}

.cut {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nowrap {
  white-space: nowrap;
}

.blue-bg {
  color: white!important;
  background-color: var(--ttblue)!important;
}
.azure-bg {
  background-color: azure !important;
}
.purple-bg {
  background-color: var(--ttpurple)  !important;
}

.blue-shade-bg {
  color: white!important;
  background-color: #2196F3 !important;
}

.font-white {
  color: white!important;
}

.border-left {
  border-left: 1px solid grey;
}



.height-60 {
  height: 60vh !important;
}








.width-100 {
  width: 80px !important;
}
.width-150 {
  width: 150px !important;
}
.width-200 {
  width: 200px !important;
}
.width-250 {
  width: 250px !important;
}
.width-400 {
  width: 400px !important;
}
.width-500 {
  width: 500px !important;
}
.width-600 {
  width: 600px !important;
}
.width-800 {
  width: 800px !important;
}
.width-1200 {
  width: 1200px !important;
}


.line-height {
  line-height: 1.5;
}
.line {
  border-bottom: 1px solid lightgrey;
}

.h-line {
  border-right: 2px solid lightgrey;
}
.font-12 {
  font-size: 12px !important;
}
.font-14 {
  font-size: 14px !important;
  white-space: nowrap;
}
.font-18 {
  font-size: 18px !important;
}

.font-30 {
  font-size: 30px !important;
}

.pointer {
  cursor: pointer;
}



.card-title {
  border-bottom: 1px solid lightgrey;
  margin-bottom: 20px;
  font-weight: bold;
}

.back-link-title {
  display: flex;
  align-items: center;
  margin: 15px 0;
  max-width: 300px;
  color: var(--ttblue);

}

.wrap {
  flex-wrap: wrap; /* Key property for wrapping */
}

.center {
  align-items: center;
  justify-content: center;
}
.center-justify {
  justify-content: center;
}

.center-align {
  align-items: center;
}

.top-align {
  align-items: flex-start;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align:right;
}

.text-left {
  text-align:left !important;
}

.grow {
  display: flex;
  flex-grow: 1;
  width: 100% !important;
}

.disabled {
  pointer-events: none; /* Prevents click events */
  opacity: 0.6; /* Optional: Reduces opacity for visual indication */
}

.selected {
  background-color: azure;
  color: black;
}

.bold {
  font-weight: 600;
}

.margin {
  margin: 5px;
}

.margin-10 {
  margin: 10px !important;
}

.margin-top-25 {
  margin: 25px 0 10px 0;
}
.margin-bottom-25 {
  margin: 0 0 25px 0;
}

.margin-top-10 {
  margin-top: 10px
}

.margin-0-10 {
  margin: 0 10px;
}

.margin-10-0 {
  margin: 10px 0;
}

.margin-2-0 {
  margin: 2px 0;
}

.margin-20-0 {
  margin: 20px 0;
}

.margin-right-10 {
  margin: 0 10px 0 0;
}

.margin-right-5 {
  margin: 0 5px 0 0;
}

.margin-right-20 {
  margin: 0 20px 0 0;
}

.margin-radio {
  margin: 5px 10px 5px 10px;
}

.bottom-right-button {
  margin: auto 25px 10px auto;
}

.bottom-right-button2 {
  margin: auto 5px 10px auto;
}

.bottom-right-button3 {
  margin: 20px 5px 10px auto;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-0 {
  margin: 0 !important;
}

.no-max {
  max-width: none !important;
}


.padding-10 {
  padding: 0 10px;
}

.padding-15-0 {
  padding: 15px 0;
}

.padding-0 {
  padding:0 !important;
}

.error {
  font-size: 12px;
  color: red;
  margin-bottom: 10px;
}

.error-red {
  color:red !important;
}

.warning {
  font-size: 14px;
  font-weight: bold;
  color: darkorange;
}


/* HEADER ======================================= */
/* HEADER ======================================= */
/* HEADER ======================================= */
.notification-banner {
  display:flex;
  margin-left: 100px;
  margin-bottom: 20px;
  /*margin-top: 60px;*/
  padding: 10px;
  background: var(--ttpurple);
  color: var(--font-primary-dark);
  width: 100%;
  cursor: pointer;
}

.header-nav {
  background: #f9fafb;
  width: calc(100vw - 100px);
  height: 60px;
  position: fixed;
  top: 0;
  left: 100px;
  box-shadow: 0 2px 12px #0000000d;
}

.header-nav-inner {
  display: flex;
  align-items: center;
  /*justify-content: center;*/
  height: 100%;
  transition: .3s height;
  padding: 0 24px;
}

.header-nav-title {
  font-weight: 600 !important;
  white-space: nowrap;
  color: var(--font-primary-dark);
  font-size: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header-user {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  font-size: 24px;
  border-radius: 100%;
  border: none;
  margin-left: 10px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--theme-secondary-dark);
  color: var(--font-secondary-light);
  cursor: pointer;
  position: relative; /* for user profile menu */
}

.user-profile-menu {
  position: absolute;
  top: 100%; /* Position the menu below the button */
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  list-style: none;
  min-width: 200px;
  font-size: 14px;
  color: var(--font-primary-dark);
  padding: 0;
  /* Added z-index: 1; for stacking context */
  z-index: 1;
}

.user-profile-menu li {
  padding: 10px;
  cursor: pointer;
}

.user-profile-menu li:hover {
  background-color: #f5f5f5;
}

.user-profile button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.user-profile button:focus {
  outline: none;
}


.header-debug {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: right;
}

.debug-msg {
  background-color: #2196F3;
  color: white;
  padding: 10px;
  font-size: 12px;
}

.debug-card {
  border: 3px dotted #2196F3 !important;
  padding: 10px !important;
  font-size: 12px;
}

.help {
  color: grey;
  padding: 10px;
  font-size: 11px;
}

.help-link {
  text-decoration: underline;
  color:grey;

}

/* LEFT NAV ======================================= */
/* LEFT NAV ======================================= */
/* LEFT NAV ======================================= */

.left-nav {
  height: 100%;
  width: 100px;
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 0px 20px 20px 20px;
  background: linear-gradient(to bottom,var(--theme-primary-blue) 50%,azure 550%);
}

.audit-cake-logo {
  font-family: Monoton !important;
  font-size: 35px;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 30px;  
  margin-top: 15px;
  opacity: 1;
  color: white;
  cursor: pointer;
}

.left-nav-item-logo {
  text-decoration: none;
  border: none;
  padding: 0;
  overflow: visible;
  color: inherit;
  width: 100%;
  background: transparent;
  cursor: pointer;
  height: 60px;
  border-radius: 8px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 8px;
  opacity: .5;
  position: relative;
  transition: .2s;
  outline: none;
  color: var(--font-secondary-light);
  margin-bottom: 30px;
}

.left-nav-item-logo-icon {  
  font-size: 35px;
}

.left-nav-item {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    color: var(--font-secondary-light);
    width: 100%;
    height: 60px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
    opacity: .55;
    position: relative;
    transition: .2s;
    font-size: 12px;
}

.left-nav-item-carrier {
    height: 70px !important;
}

.left-nav-item:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -10px;
    height: 70px;
    width: 80px;
    background: white;
    opacity: 0;
    transform: scale(0);
    transition: .4s cubic-bezier(.175,.885,.32,1.15);
    border-radius: 10px;
}

.left-nav-item:hover:before,.left-nav-item:focus:before {
    transform: scale(.875);
    opacity: .225;
}

.left-nav-item:hover {
    opacity: 1;
}

.left-nav-item-selected {
    opacity: 1 !important;
    color: white;
    font-weight: 800;
}

.left-nav-item-icon {
    font-size: 25px;
}

.left-nav-item-bottom {
    margin-top: auto;
}

/* Styles for hamburger menu (add these within the media query) */
.h-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  cursor: pointer;
  top: 20px;
  left: 15px;
  height: 20px;
  width: 30px; 
}

.h-menu span {
  width: 100%;
  height: 3px;
  background-color: var(--ttblue);
  transition: all 0.3s ease-in-out;
}

.h-menu-exp {
  position: absolute;
  top: 100%; /* Position the menu below the button */
  left: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  list-style: none;
  min-width: 200px;
  font-size: 14px;
  color: var(--font-primary-dark);
  padding: 0;
  /* Added z-index: 1; for stacking context */
  z-index: 1;
}

.h-menu-exp li {
  padding: 10px;
  cursor: pointer;
}

.h-menu-exp li:hover {
  background-color: #f5f5f5;
}


.carrier-switcher {
  position: relative; /* For positioning the dropdown */
  display: inline-block; /* Or flex, depending on your layout */
  margin-top: 15px;
  z-index: 100; /* Make sure this is HIGHER than the search-bar-container's z-index */
}

.current-carrier {
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc; /* Optional border */
  background-color: #f9f9f9; /* Optional background */
}

.carrier-logo {
  height: 50px; /* Adjust size as needed */
  width: 50px;
  vertical-align: middle;
  background-color: white;
  border-radius: 50%;
}

.carrier-dropdown {
  position: absolute;
  top: 0; /* Adjust top position if needed */
  left: calc(100% + 5px); /* Position to the right */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 100; /* Ensure it's above other elements */
  width: 400px;
}

.carrier-item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
}

.carrier-item:hover {
  background-color: #eee;
}

.carrier-item .carrier-logo {
  margin-right: 8px;
}


/* MAIN CONTAINER ======================================= */
/* MAIN CONTAINER ======================================= */
/* MAIN CONTAINER ======================================= */
.admin-container {
  display:flex;
  flex-direction: column;
  padding: 24px;
  margin-left: 100px;
  /*margin-top: 80px; removed since removed header */
}

/* AUDITS  ======================================= */
/* AUDITS  ======================================= */
/* AUDITS  ======================================= */
.audits-container {
  margin-left: 100px;
  display:flex;
  flex-direction: row;
  flex-grow: 1;
  background-color: white;
}

.audits-table-container {
  max-height: 100vh; /*Set a max height for the card*/
  overflow-y: auto; /* Enable vertical scrolling */
  /*box-shadow: 2px 0 12px #0000000d;*/
}

.audit-card {
  padding: 10px;
  display: flex;
  flex-grow: 1;
  min-width: 580px;
  flex-direction: column;
  color: var(--font-primary-dark);
  background-color: lavender !important;
  box-shadow: -2px 0 12px #0000000d;
  max-height: 100vh; /*Set a max height for the card*/
  overflow-y: auto; /* Enable vertical scrolling */
  /*box-shadow: 5px 0 10px -5px rgba(0, 0, 0, 0.2), 
              -5px 0 10px -5px rgba(0, 0, 0, 0.2); /* Left shadow */

}

.audit-panel {
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: var(--font-primary-dark);
  box-shadow: -2px 0 12px #0000000d;
  min-width: 580px;
  max-height: 100vh; /*Set a max height for the card*/
  overflow-y: auto; /* Enable vertical scrolling */
}

.audit-panel-title {
  font-weight: bold;
  color: var(--font-primary-dark);
  font-size: 18px;
}

.audit-status {
  border: 1px solid var(--ttblue);
  color: var(--ttblue);
  padding: 5px 15px;
  font-size: 12px;
  display: flex;
  font-weight: 600;
  position: relative; /* for user profile menu */
}

.audit-flags {
  border: 1px solid var(--theme-secondary-light);
  border-radius: 4px;
  color: var(--theme-secondary-dark);
  background: var(--ttpurple);
  padding: 4px 7px;
  font-size: 12px;
  display: flex;
  font-weight: 600;
}

.audit-details-fields {
  width: 300px;
  display: flex;
  flex-direction: column;
}

.audit-bottom-section {
  border-top: 1px solid lightgrey;
  background-color: #f4f4f8;
  display: flex;
  flex-grow: 1;
}

/* AUDIT STATUS ***********************************/

.status-menu {
  margin: 5px 0px;
  position: absolute;
  top: 100%; /* Position the menu below the button */
  background-color: #fff;
  right: 0;
  border: 1px solid #ddd;
  border-radius: 7px;
  list-style: none;
  min-width: 200px;
  font-size: 14px;
  color: var(--font-primary-dark);
  padding: 0;
  /* Added z-index: 1; for stacking context */
  z-index: 1;
  box-shadow: var(--theme-box-shadow-dark);
}

.status-menu li {
  padding: 15px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  display: flex;
  flex-direction: row;
}

.status-menu li:hover {
  background-color: #f5f5f5;
}


/* /BULK MENU ***********************************/


/* DRAWER ======================================= */
/* DRAWER ======================================= */
/* DRAWER ======================================= */

/* Overlay */
.overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 40;
}

/* Drawer */
.drawer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  background: white;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 50;
  display: flex;
}

/* FORM ======================================= */
/* FORM ======================================= */
/* FORM ======================================= */

.form-section-container {
  margin: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}

.form-section {
  border-top: 1px solid lightgrey;
  margin: 10px 0;
  padding-top: 10px;
  color: grey;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-transform: capitalize;
}

.form-row {
  display: flex;
  align-items: center;
}
.form-field {
  display: flex;
  flex-direction: column;
  margin: 0 10px 20px 0;
}

.form-label {
  font-size: 12px;
  color: lightslategray;
}

.form-input {
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 10px;
  margin-top: 3px;
  outline: none; /* Remove default outline on focus */
  color: var(--font-primary-dark);
  font-size: 14px;
  max-width: 320px;
}

.form-input-500 {
  width: 500px !important;
  max-width: none !important;
}

.fi-max-120 {
  white-space: wrap;
  word-wrap: break-word;
}

.fi-small {
  width: 130px !important;
}

.fi-x-small {
  width: 70px !important;
}

.fi-xx-small {
  width: 50px !important;
}

.fi-med {
  width: 150px !important;
}

.fi-large {
  width: 100% !important;
}

.admin-fi-large {
  width: 100% !important;
  max-width: none;
}

.form-select {
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 2px 5px;
  max-width: 100px;
  background-color: rgba(0, 0, 0, 0.01); /* Adjust alpha for desired transparency */
 }

.form-button-group {
  display: flex;
  align-items: flex-start;
  /*margin: 20px auto;*/
}

.form-button {
  border: 1px solid var(--ttblue);
  border-radius: 5px;
  padding: 15px 15px;
  cursor: pointer;
  text-decoration: none;
  color: var(--ttblue);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.form-button-admin {
  border: 1px solid var(--ttblue);
  border-radius: 5px;
  padding: 15px 15px;
  cursor: pointer;
  text-decoration: none;
  color: var(--ttblue);
  display: flex;
  align-items: center;
  font-size: 20px;
}

.form-button-icon {
  font-size: 20px;
  padding: 0 !important;
}
/* only matters if you make it a <button> vs <div>
 .form-button:disabled {
    background: #f3e7f3;
    color: #000000;
    cursor: not-allowed;
    opacity: .6;
  }

.form-button:active {
    transform: scale(0.98);
  }
  */

.action-button {
  border: 1px solid var(--ttblue);
  border-radius: 20px;
  padding: 10px 20px;
  margin: 30px;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px; 
  color: var(--ttblue);
}

.primary {
  color: white !important;
  background-color: var(--ttblue) !important;
}

.search-filter {
  margin: 0 10px;
  width: 260px;
  padding: 7px 10px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  outline: none; /* Remove default outline on focus */
}


/* PROGRESS BAR ***********************************/
/* PROGRESS BAR ***********************************/
/* PROGRESS BAR ***********************************/

.p-bar-container {
  width: 100%;
  margin: 0 auto;
  padding: 0 25px;
  max-width: 600px;
  position: relative;
  display: flex;
} 

.p-bar {
  content: '';
  position: absolute;
  background: var(--theme-secondary-light);
  height: 8px;
  width: calc(100% - 50px);
  top: 0%;
  left: 25px;
}

.p-bar-a-left {
  content: '';
  position: absolute;
  top: 3%;
  left: -7px;
  color: var(--ttblue);
  font-size: 30px;
}

.p-bar-a-right {
  content: '';
  position: absolute;
  top: 3%;
  left: calc(100% - 40px);
  color: var(--ttblue);
  font-size: 30px;
}

/*
.p-bar-a-left {
  content: '';
  position: absolute;
  top: 50%;
  left: -7px;
  color: var(--ttblue);
  font-size: 30px;
}

.p-bar-a-right {
  content: '';
  position: absolute;
  top: 50%;
  left: calc(100% - 40px);
  color: var(--ttblue);
  font-size: 30px;
}
*/

/*
.p-bar-a-left {
  content: '';
  position: absolute;
  top: -20px;
  left: -20px;
  color: var(--ttblue);
  font-size: 30px;
}

.p-bar-a-right {
  content: '';
  position: absolute;
  top: -20px;
  left: calc(100% - 32px);
  color: var(--ttblue);
  font-size: 30px;
}
*/


/* STEP PROGRESSION ***********************************/
/* STEP PROGRESSION ***********************************/
/* STEP PROGRESSION ***********************************/


.step-progress-container {
  width: 100%;
  margin: 0 auto;
  padding: 0 25px;
  max-width: 600px;
} 
  
.step-container {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  position: relative;
}

.step-container:before {
  content: '';
  position: absolute;
  background: var(--theme-secondary-light);
  height: 4px;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.step-bar {
  content: '';
  position: absolute;
  background: var(--theme-secondary-dark);
  height: 4px;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.step-bar1 {
  width: 0%;
  background: var(--theme-primary-blue);
}

.step-bar2 {
  width: 33%;
  background: var(--theme-primary-blue);
}

.step-bar3 {
  width: 66%;
  background: var(--theme-primary-blue);
}

.step-bar4 {
  width: 100%;
  background: var(--theme-primary-blue);
}

.step-wrapper {
  position: relative;
  z-index: 1;
  cursor: pointer;
}
 
.step-style {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-style-complete {
  background-color: var(--theme-primary-blue);
  color: white;
  font-size: 12px;
  font-weight: 800;
  -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-46deg);
}

.step-style-incomplete {
  background-color: #f4f4f8;
  border: 1px solid var(--theme-secondary-light);
}

.step-style-active {
  background-color: #f4f4f8;
  border: 1px solid var(--theme-primary-blue);
  color: var(--theme-primary-blue);
  font-size: 12px;
  font-weight: 800;
  -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-46deg);
}
  
.step-count {
  font-size: 19px;
  color: var(--theme-secondary-light)
}

.step-count-selected {
  font-size: 19px;
  color: var(--theme-secondary-dark)
}
  
.step-label-container {
  position: absolute;
  top: 36px;
  left: 50%;
  transform: translate(-50%, -50%);
}
 
.step-label {
  margin-top: 10px;
  font-size: 14px;
  color: var(--ttblue);
}

.step-label-incomplete {
  color: var(--theme-secondary-light);
}

/* SEARCH ***********************************/
/* SEARCH ***********************************/
/* SEARCH ***********************************/

.search-bar-container {
  display: flex;
  margin: 0 10px;
  justify-content: center; /* Optionally center content horizontally */
  align-items: center; /* Vertically center content within them */
  position: relative; /* Establish a positioning context */
  z-index: 0; /* Keep it at 1 or remove it */
}

.search-icon {
  position: absolute; /* Position relative to the search box */
  top: 50%; /* Vertically center the icon */
  right: 15px; /* Adjust horizontal position as needed */
  transform: translateY(-50%); /* Fine-tune vertical alignment */
  font-size: 20px; /* Larger size */
  cursor: pointer;
}

.search-bar {
  display: flex;
  padding: 10px 20px; /* make righthandside 50 if you want to add search icon */
  flex: 1;
  border: 1px solid lightgrey;
  border-radius: 5px;
  font-size: 16px;
  outline: none; /* Remove default outline on focus */
  z-index: 0; /* Keep it at 1 or remove it */
}

.search-bar:focus {
  border-color: var(--ttblue) !important;
}

.search-bar::-webkit-search-cancel-button {
  cursor: pointer;
}
  
`

/* LOGIN ***********************************/
/* LOGIN ***********************************/
/* LOGIN ***********************************/

.login-outer {
  display: flex;
  flex-direction: column;
  flex: 2;
}

.login-box {
  margin:auto;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-form {
  margin: 0 20px;
  width: 300px;
}

.login-button-group {
  margin-bottom: 20px;
}

.login-button {
  margin: 5px 0 !important;
  text-align: center;
  padding: 10px !important;
}

.login-or {
  height: 10px;
  border-bottom: 1px solid lightgrey; 
  text-align: center;
  margin: 30px 35px;
  display: hidden;
}

.login-or-text {
  background-color: white; 
  padding: 0 10px;
  border: 1px solid lightgrey; 
  display: hidden;
  color: dimgrey;
}

.sign-in-google {
  background-color: white;
  color: dimgray;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign-in-google-svg {
  height: 18px;
  margin-right: 20px;
}

.login-message {
  color: dimgray;
  margin: 10px auto;
  font-size: 12px;
  overflow-wrap: break-word;
  text-align: center;
}


/* ADMIN ***********************************/
.admin-title {
  display: flex;
  padding: 10px;
  align-items: center;
  font-weight: bold;
}

.admin-title-right {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-title-icon {
  width: 35px;
  font-size: 20px;
  text-overflow: unset;
}

.admin-date {
  font-size: 12px;
  color: grey;
  margin-left: 20px;
}

.admin-orders-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 10px;
  font-size: 12px;
  overflow-x: scroll; 
}



.tabs {
  display: flex;
}

.tab {
  padding: 0px 0px 10px 0px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 25px;
  margin-top: 20px;
}

.tab:hover{
    overflow: visible; 
    overflow-wrap: break-word;
    white-space: normal;
    height:auto;  /* just added this line */
    color: black;
}

.tab-s {
  border-bottom: 2px solid var(--ttblue);
}

.order {
  display:flex;
  align-items: center;
  border-top: 1px solid lightgrey;
}

.order:hover{
    background-color: azure;
    color: black;
    cursor: pointer;
}

.order:last-child {
  border-bottom: 1px solid lightgrey;
}

.of {
  padding: 8px;
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

.of:hover{
    overflow: visible; 
    overflow-wrap: break-word;
    white-space: normal;
    height:auto;  /* just added this line */
}

.of-header {
  font-weight: bold;
}

.of-tiny {
  width: 50px;
}

.of-small {
  width: 110px;
}

.of-med {
  width: 150px;
}

.of-large {
  width: 250px !important;
}

.of-flags {
  width: 100px !important;
}

.of-icon {
  width: 35px !important;
  font-size: 16px;
  text-overflow: unset;
  white-space: nowrap;
  overflow: hidden;
}

.of-icon-label {
  width: 35px;
}

.of-status {
  border: 1px solid var(--theme-secondary-light);
  color: var(--ttblue);
  padding: 2px;
  margin: auto;
  cursor: pointer;
  font-size: 12px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}


.of-status-icon-cont {
  padding: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
}

.of-status-done {
  color: var(--ttblue);
  font-weight: 900;
}

.of-status-incomplete {
  color: lightslategray;
}

.of-center {
  justify-content: center;
}

/* REVIEW DATA ======================================= */
/* REVIEW DATA ======================================= */
/* REVIEW DATA ======================================= */
.upload-container {
  overflow-y: auto;
}

.upload-field {
  padding: 0 !important;
}

.upload-form-field {
  font-size: 10px;
}


/* DETAILS ***********************************/

.details-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  border-bottom: 2px solid lightgrey;
  padding: 10px;
}

.details-history {
  background-color: var(--theme-primary-blue);
  color: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin: 0 10px;
  padding: 10px;
}

.details-workflow {
  background-color: mistyrose;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin: 0 10px;
  padding: 10px;
}

.details-fields {
  background-color: lavender;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin: 0 10px;
  padding: 10px;
}

.workflow-container {
  font-size: 12px;
}

.details-comments {
  background-color: orange;
  color: black;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin: 0 10px;
  padding: 10px;
  font-size: 13px;
}

.comments-content {
    flex-grow: 1; /* Allow the message to take up available space */
    margin: 0 5px; /* Add some horizontal margin */
    /* max-width: 400px; Remove this, not needed with break word */
    word-break: break-word; /*Crucial for long words */
    overflow-wrap: break-word; /*Crucial for long words */

}

.comments-row {
    display: flex;
    flex-direction: row;
    word-break: break-word; /*Crucial for long words */
    overflow-wrap: break-word; /*Crucial for long words */
    font-size: 12px !important;
    align-items: flex-start;
    padding: 5px;
}


.comments-column {
    white-space: nowrap; /* Prevent timestamp from wrapping */
    margin: 0 5px;
}

.comments-margin {
  margin: 5px 10px 5px 0;
}

.details-atts {
  background-color: seashell;
  color: black;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin: 0 10px;
  padding: 10px;
  font-size: 13px;
}

.details-history-label {
  font-size: 16px;
}

.d-history-row {
  display: flex;
  padding-bottom: 10px;
}

.d-history-header {
  margin-top: 5px;
  font-weight: bold;
}

.d-row-label {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.d-row-label:hover{
    overflow: visible; 
    overflow-wrap: break-word;
    white-space: normal;
    height:auto;  /* just added this line */
}

.d-history-item {
  font-size:12px;
  font-style: italic;
}

.d-history-date {
  width: 250px !important;
}

.d-history-op {
  font-weight: 600;
}

.details-half {
  display: flex;
  width: 50%;
}

.details-field {
  display: flex;
  flex-direction: column;
  margin: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.step-details-field {
  display: flex;
  flex-direction: column;
  margin: 5px;
  font-size: 12px;
  border: 1px solid pink;
}
/*
.details-field:hover{
    overflow: visible; 
    white-space: normal;
    height:auto;  
    background-color: azure;
    color: black;
}
*/
.details-field:hover{
  background-color: #E6E6F1;
  font-weight: bold;
}

.details-label {
  color: grey;
  font-size: 10px;
}


/* AUDIT ======================================= */

.audit {
  margin-left: auto;
  display: flex;
  align-items: center;
}

/* ATTACHMENTS ======================================= */


.pdf-viewer-container {
  margin: 30px;
}




/* TOGGLE ======================================= */
/* TOGGLE ======================================= */
/* TOGGLE ======================================= */
.toggle {
  display: flex;
  align-items: center;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 15px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* MODAL ***********************************/
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.select-step-type {
  display: flex;
  width: 300px !important;
  max-width: 300px !important;

}

/* INSURED ***********************************/
/* INSURED ***********************************/
/* INSURED ***********************************/
.i-header {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.i-center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  flex-grow: 1;
}

.i-nav-buttons {
  border-top: 1px solid var(--theme-secondary-light);
  margin-top: 10px;
}

.i-card {
  width: 100%;
  max-width: 600px;
  border: 1px solid lightgrey;
  border-radius: 25px;
  margin: 5px;
  max-height: 80vh;
  overflow-y: auto; /* Enable vertical scroll */
  background-color: white;
  margin-top: 20px;
  color: var(--font-primary-dark);
}

.i-card-review {
  width: 100%;
  max-width: 600px;
  border: 1px solid lightgrey;
  border-radius: 25px;
  margin: 5px;
  background-color: white;
  margin-top: 20px;
  padding-top: 20px;
  color: var(--font-primary-dark);
}

.i-card-review-large {
  width: 100%;
  max-width: 1000px;
  border: 1px solid lightgrey;
  border-radius: 25px;
  margin: 5px;
  background-color: white;
  margin-top: 20px;
  padding-top: 20px;
  color: var(--font-primary-dark);
}

.i-vert-bar {
  width: 1px; /* Set the width of the divider line */
  background-color: var(--theme-secondary-light);
  display: flex;
  align-items: center;
  justify-content: center;
}

.i-button-font {
  font-size: 20px !important;
}

.i-or {
  height: 10px;
  border-bottom: 1px solid lightgrey; 
  text-align: center;
  margin: 30px 35px;
  display: hidden;
}

.i-or-text {
  background-color: white; 
  padding: 0 10px;
  border: 1px solid lightgrey; 
  display: hidden;
  color: dimgrey;
}

.i-logo {
  width: 200px;
  padding-top: 6px;
}

.i-sml-logo {
  width: 150px;
  padding-top: 12px;
  padding-bottom: 6px;
}

.i-form {
  padding: 20px 30px 5px 30px;
  font-size: 14px;
}

.i-form-large {
  padding: 20px 30px 5px 30px;
  font-size: 14px;
  overflow-x: auto; /* Enable horizontal scrolling */

}

.i-form-smaller-text {
  font-size: 12px;
}

.i-sales-cont-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.i-sales-cont-inner {
  width: 270px;
}

.i-sales-row-total {
  font-size: 12px;
  color: var(--ttgold);
  display: flex;
  margin-left: auto;
  margin-bottom: 20px;
}

.i-sales-total {
  justify-content: right;
  border-top: 1px solid var(--ttblue);
}

.i-radio-val {
  font-size: 14px;
  color: var(--font-primary-dark);
}

.i-answer {
  font-size: 35px;
}

.i-answer-submitted {
  font-size: 60px;
}

.i-sub-title {
  font-size: 35px;
  color: var(--font-primary-dark);
  padding: 40px;
  margin-bottom: 50px;
}

.i-title {
  margin: 20px auto;
  padding: 30px 30px;
  border: 1px solid lightgrey;
}

.i-field {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  font-size: 16px;
}

.i-label {
  color: grey;
  font-size: 12px;
}

.i-val {
  font-size: 16px;
  white-space: wrap;
  word-wrap: break-word;
}

.i-val-small {
  font-size: 14px;
}

.table-container {
  width: 100%; /* Set a width for container */
  overflow-x: auto;
  white-space: nowrap; /* Prevent text wrapping */
  margin-bottom: 10px;
}

.data-table {
  border-collapse: collapse;
  width: 100%;
  margin: 5px auto;
}

.i-data-header {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  background-color: #f2f2f2;
  width: 150px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.total-row {
  font-weight: bold;
}

.i-steps {
  margin: 0 auto;
  width: 60%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.i-step-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 40px 20px;
}

.i-step-f {
  margin:auto;
  padding: 15px;
  width: 200px;
}

.i-step-circle {
  display: inline-flex; /* Allow text wrapping */
  justify-content: center; /* Center text horizontally */
  align-items: center; /* Center text vertically */
  width: 50px; /* Adjust width as needed */
  height: 50px; /* Adjust height as needed */
  border-radius: 50%; /* Create the circle */
  color: #000; /* Set text color */
  font-size: 24px; /* Adjust font size as needed */
  font-weight: bold; /* Add boldness (optional) */
  margin-bottom: 20px;
}

.i-step-title {
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 40px;
}

.i-step-description {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
  border: 1px solid lightgrey;
  height: 130px;
  padding: 10px;
}

.i-step-1 {
  background-color: #AAD15B;
}

.i-step-2 {
  background-color: #6FCEA5;
}

.i-step-3 {
  background-color: #71D2E1;
}

.i-step-4 {
  background-color: #5CA6DC;
}

.i-row {
  display:flex;
  align-items: center;
  width: 600px;
}

.i-form-field {
  display: flex;
  flex-direction: column;
}

.i-form-input {
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 10px;
  outline: none; /* Remove default outline on focus */
  font-size: 14px;
  width: 260px;
  margin-bottom: 10px;
}

.i-step-form-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 20px;
  border: 1px solid lightgrey;
  width: 40%;
}

.i-step-form-header {
  display: flex;
  text-transform: uppercase;
  padding: 10px;
  margin-bottom: 30px;
  font-weight: bold;
  align-items: center;
}
.i-step-form-header-item {
  padding-right: 5px;
}

.i-step-form-header-button {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  text-decoration: none;
  margin-left: auto;
}

.i-step-form-inner {
  padding: 20px;
}
.i-step-form-instructions {
  margin-bottom: 10px;
  
}

/* BULK MENU ***********************************/

.bulk-menu-outer {
  border: 1px solid var(--ttpurple);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  display: flex;
  cursor: pointer;
  position: relative; /* for user profile menu */
  overflow-y: auto;
}

.bulk-selected-num {
  color: navy;
  border: 1px solid navy;
  border-radius: 10px;
  background-color: lavender;
  padding: 5px 0px 5px 15px;
}

/* DROPZONE ***********************************/

.dropzone {
  border: 2px dashed #ccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
}

.dropzone:hover {
  border-color: #007bff;
}

.dropzone-input {
  display: none;
}

.attach-field {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 0;
  font-size: 14px;
}

.attach-field-select {
  display: flex;
  margin-right: auto;
  width: 150px;
}

.attach-instr {
  margin-left: 20px;
}
.attach-instr li {
  padding: 3px 0;
}

.attach-icon {
  padding:0 !important;
  font-size: 18px;
  text-overflow: unset;
  white-space: nowrap;
}

.attach-num {
  font-size: 14px;
}

.file-tag {
  border:none;
  padding: 10px;
  max-width: 210px;
}

.file-icon {
  padding:0 !important;
  font-size: 16px;
  text-overflow: unset;
  white-space: nowrap;
  overflow: hidden;
  width: 35px;
}

.comments-icon {
  padding:0 !important;
  font-size: 16px;
  text-overflow: unset;
  white-space: nowrap;
  overflow: hidden;
}

.steps-icon {
  padding:0 !important;
  font-size: 16px;
  text-overflow: unset;
  white-space: nowrap;
  width: 35px;
}

.steps-icon2 {
  padding:0 !important;
  font-size: 30px;
  text-overflow: unset;
  white-space: nowrap;
}


/* FILTER CHIPS ***********************************/
/* FILTER CHIPS ***********************************/
/* FILTER CHIPS ***********************************/
/* FILTER CHIPS ***********************************/

.filter-chip {
  background-color: white;
  padding: 5px 10px 5px 15px;
  color: gray;
  margin: 5px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.filter-chip-selected {
  background-color: azure;
  color: var(--font-primary-dark);
  border-radius: 7px;
  border: 1px solid lightgrey;
  padding: 5px 10px 5px 15px;
  margin: 5px;
  font-size: 14px;
  font-weight: bold !important;
  display: flex;
  justify-content: center;
  height: 26px;
}

.filter-chip-focus {
  border-bottom: 2px solid var(--ttblue);
}

.filter-chip-label {
  margin-right: 10px;
  font-weight: bold;
  cursor: pointer;
}

.filter-chip-dropdown {
  background-color: white;
  color: gray;
  display: flex;
  flex-direction: column;
  padding: 2px 5px;
  margin: 0 5px;
  border: 1px solid lightgrey;
  box-shadow: 0 2px 12px #0000000d;
  font-size: 14px;
}
.filter-checkbox-label {
  margin: 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.filter-reset {
  border: 1px solid var(--ttblue);
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  text-decoration: none;
  color: var(--ttblue);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-left: auto;
  margin-right: 5px;
}

/* REPORTS  ***********************************/
/* REPORTS  ***********************************/
/* REPORTS  ***********************************/

.r-menu {
  width: 200px;
  height: 200px;
  min-width: 40px;
  min-height: 40px;
  font-size: 24px;
  border-radius: 100%;
  border: 1px solid var(--ttpurple);
  margin-left: 10px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: navy;
  cursor: pointer;
  position: relative; /* for user profile menu */
}

.reports-container {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 10px;
  border: 1px solid lightgrey;
  margin: 5px;
  background-color: white;
  color: var(--font-primary-dark);
}

.report-row {
  display:flex;
  align-items: center;
  border-top: 1px solid lightgrey;
  overflow-x: auto;
}

.report-row:last-child {
  border-bottom: 1px solid lightgrey;
}

.rf {
  padding: 8px;
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

.rf-sel {
  cursor: pointer;
}

.rf-sel:hover {
  background-color: azure;
}

.rf-header {
  font-weight: bold;
}

.rf-small {
  width: 35px;
}

.rf-med {
  width: 150px;
}

.rf-large {
  width: 200px !important;
}

.rf-status {
  border: 1px solid var(--theme-secondary-light);
  color: var(--ttblue);
  padding: 2px;
  margin: auto;
  cursor: pointer;
  font-size: 12px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.rf-status-icon-cont {
  padding: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
}

.outer-container {
  width: 100%; /* Set the desired width for the outer container */
  display: flex;
}

.inner-content {
  display: flex;
  flex-grow: 1;

  flex-wrap: nowrap; /* Ensures all inner divs stay on one line */
  overflow-x: auto; /* Enables horizontal scrollbar */
}

.inner-div {
  padding: 10px;
  width: 200px;
  margin: 5px;
  border: 1px solid lightgray;
  background-color: #f5f5f5; /* Optional background color for inner divs */
}

.inner-content-wrapper {
  display: flex;
  flex-direction: column; /* Stack rows vertically */
  flex-grow: 1;
  width: 100%;

}

.inner-row {
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-wrap: wrap; /* Allow inner divs to wrap within each row */
  overflow-x: auto; /* Horizontal scrollbar for each row */
}

.multi-row-scroller {
  display: flex;
  flex-direction: column;
  width: 100%; /* Adjust width as needed */
}

.xrow {
  display: flex;
  /* Adjust height as needed */
  min-height: 50px;
}

.xcolumn-small {
  flex: 1; /* Make all columns equal width */
  padding: 10px;
  border: 1px solid darkgrey;
  min-width: 60px;
  max-width: 80px;

  color: black !important;
  background-color: lightgrey !important;
}

.xcolumn {
  flex: 1; /* Make all columns equal width */
  padding: 10px;
  border: 1px solid lightgray;
  min-width: 245px;
}

.xcolumn-header {
  color: white;
  background-color: darkgrey;
}

.row-wrapper {
  display: flex;
  flex-wrap: nowrap; /* Ensure all rows stay on one line */
  overflow-x: auto; /* Enable horizontal scroll for all rows */
  flex-direction: column;
}




/* PHONE MODE  ***********************************/
/* PHONE MODE  ***********************************/
/* PHONE MODE  ***********************************/

.phone-mode-container {
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
  font-size: 30px;
}

.pm-title {
  align-items: center;
  margin-left: 10px;
  font-weight: bold;
  display: flex;
}

.pm-font {
  font-size: 50px !important;
  margin:0;
}

.pm-actions {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  margin-bottom: 30px;
  margin-top: 20px;
}

.pm-contact {
  border-radius: 100%;
  border: none;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
  background: var(--theme-secondary-dark);
  color: var(--font-secondary-light);
  overflow: hidden; /* Clip overflowing text */
  text-overflow: ellipsis; /* Add ellipsis for long text */
  white-space: nowrap; /* Prevent wrapping to multiple lines */
  width: 200px;
  height: 200px;
}

.pm-label {
  font-size: 18px;
  text-align: center;
  overflow: hidden; /* Clip overflowing text */
  text-overflow: ellipsis; /* Add ellipsis for long text */
  white-space: nowrap; 
}

.pm-notes {
  font-size: 16px;
}

.pm-icon {
  padding:0 !important;
  font-size: 50px;  
}

.pm-record-button {
  border: 1px solid var(--ttblue);
  border-radius: 15px;
  padding: 25px 0;
  margin: 30px 50px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  background-color: var(--ttblue);
  color: white;
}



/* COOKIE MODE    ***********************************/
/* COOKIE MODE    ***********************************/
/* COOKIE MODE    ***********************************/
.cookie-mode-container {
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
  font-size: 14px;
}

.cookie-card {
  border: 1px solid lightgrey;
  margin: 5px;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: var(--font-primary-dark);
}

/*
.cookie-card-inner {
  overflow: hidden;
  max-height: 100px;
  transition: max-height 0.5s ease-out; 
}

.cookie-card-inner.expanded {
  max-height: 500px;
}
*/


/* SPINNER    ***********************************/
/* SPINNER    ***********************************/
/* SPINNER    ***********************************/

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}


.processing-bar-container {
  width: 100%;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.processing-bar {
  height: 30px;
  background-color: var(--ttblue);
  text-align: center;
  line-height: 30px;
  color: white;
  transition: width 0.05s linear; /* Smooth transition */
}



/* TOAST  ***********************************/
/* TOAST    ***********************************/
/* TOAST    ***********************************/

.toast {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--ttblue);
  min-width: 200px;
  color: white;
  padding: 12px 16px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  animation: fade-in 0.3s ease, fade-out 0.3s ease 3s forwards;
}

.toast-icon {
  margin-right: 8px;
}

.toast-message {
  font-size: 14px;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translate(-50%, 10px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
    transform: translate(-50%, -10px);
  }
}


/* MARKETING SITE   ***********************************/
/* MARKETING SITE   ***********************************/
/* MARKETING SITE   ***********************************/

/* MOBILE FIRST DESIGN   ***********************************/

.m-site {
  display: flex;
  flex:1;
  flex-direction: column;
  font-family: sans-serif;
}

.m-header {
  position: sticky; /* Remains sticky on scroll */
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem; /* Adjust padding as needed */
  background-color: #fff; /* Adjust background color */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow */
  z-index: 10; /* Ensure header stays on top */
  background-color: rgba(#407BFF, 0.9);
  display: flex;
  align-items: center;
  height: 64px; 
}

.m-header-ac {
  position: sticky; /* Remains sticky on scroll */
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem; /* Adjust padding as needed */
  background-color: #fff; /* Adjust background color */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow */
  z-index: 10; /* Ensure header stays on top */
  background-color: rgba(#407BFF, 0.9);
  display: flex;
  align-items: center;
  height: 104px; 
}

.m-logo {
  height: 70px;
  padding: 12px 0;
}

.m-logo-ac {
  height: 100px;
  padding: 12px 0;
}

.m-container-outer {
  height: 100%;
  display: flex;
  flex:1;
}
.m-container {
  display: flex;
  flex-direction: column;
  flex:1;
  padding-bottom:50px;
}

.m-lhs {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: auto;
  padding: 0 40px;
}

.splash-image {
  padding-left: 30px;
}

.m-title {
  font-size: 24px; /* Adjust font size as needed */
  color: var(--font-primary-dark);
}

.m-ac-cont {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  font-size: 26px;
  color: var(--font-primary-dark); /* D9E5FF */
}

.m-error {
  font-size: 20px;
  color: red;
  margin-top: 10px;
}

.m-ac {
  font-size: 42px; /* Adjust font size as needed */
  color: #407BFF;
  text-transform: uppercase;
}

.m-auditcode-input {
  border: 1px solid lightgrey;
  border-radius: 14px;
  padding: 20px;
  outline: none; /* Remove default outline on focus */
  font-size: 24px;
  width: 100%;
}

.m-button {
  background-color: #407BFF; 
  color: white;
  width: 180px;
  padding: 15px;
  text-align: center;
  border: 1px solid lightgrey;
  border-radius: 44px;
  cursor: pointer;
}

/* WHEN DESKTOP   ***********************************/
@media (min-width: 480px) { 
  .splash-image {
    flex: 1;
    max-width: 500px;
    margin-left:auto;
  }
  
  .m-auditcode-input {
    max-width: 520px;
  }
}

/* WHEN EVEN LARGER   ***********************************/
@media (min-width: 768px) { 
  .m-title {
    font-size: 60px; 
  }

  .m-auditcode-input {
    width: 560px;
  }
}

/* WHEN EVEN LARGER   ***********************************/
@media (min-width: 1130px) { 
  .m-container-outer {
    align-items: center;
  }

  .m-container {
    flex-direction: row;
    align-items: center;
  }

  .m-lhs {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 0;
    padding: 0 60px;
  }
}

.purple-1 {
  background: linear-gradient(to bottom,var(--ttpurple) 50%,var(--ttblue) 550%);
}

.purple-2 {
  background: linear-gradient(to bottom,var(--ttpurple) 50%,#7F5EFD 550%);
}
.purple-3 {
  background: linear-gradient(to bottom,var(--ttpurple) 50%,#6633CC 550%);
}
.purple-4 {
  background: linear-gradient(to bottom,var(--ttpurple) 50%,#4D0C9A 550%);
}


/* XML */
pre {
  background-color: #f5f5f5;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-height: 800px;
  overflow: auto;
}

/* BAKER  ======================================= */
/* BAKER  ======================================= */
/* BAKER  ======================================= */
.baker-left-nav {
  height: 100%;
  width: 100px;
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 0px 20px 20px 20px;
  background: linear-gradient(to bottom,#E8E8E8 50%, grey 550%);
  font-size: 14px;
}


.baker-top-section {
  padding: 0 24px;
}

.baker-bottom-section {
  border-top: 1px solid lightgrey;
  background-color: #f4f4f8;
  display: flex;
  flex-grow: 1;
  padding: 0 24px;
}

.baker-iframe {
  width: 100%;
  height: 100%;
  border:none;
  padding-top: 2px;
  padding-bottom: 20px;
}

.baker-title {
  display: flex;
  padding: 10px 0;
  align-items: center;
  font-weight: bold;
}

.baker-atts-table {
  margin: 0 15px 35px 0;
  font-size: 12px;
}

.baker-icon {
  padding:0 !important;
  font-size: 24px !important;
  text-overflow: unset;
  white-space: nowrap;
  overflow: hidden;
  width: 35px;
}

.icon-grey {
  color: grey;
}

.b-card {
  padding: 10px 0;
  border: 1px solid lightgrey;
  margin: 2px 15px 35px 15px;
  max-height: 80vh;
  min-width: 365px;
  overflow-y: auto; /* Enable vertical scroll */
  background-color: white;
  color: var(--font-primary-dark);
}

.bgwhite {
  background-color: white;
}

.baker-email {
  max-width: 350px;
}

.baker-file-name {
  max-width: 150px;
}

.baker-file-nowrap {
  white-space: nowrap;
  font-size: 14px;
}

.baker-bd {
  color: orange;
}

.baker-email-header {
  width: 55px;
}

.call-container {
  text-align: center;
  margin-top: 50px;
}

.call-animation {
  width: 50px;
  height: 50px;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* WHEN DESKTOP   ***********************************/
@media (min-width: 480px) { 
  .baker-container {
    display:flex;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 100px;
    background-color: white;
  }
}

/* WHEN mobile   ***********************************/

@media (max-width: 479px) { 
  .baker-container {
    display:flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 10px;
    background-color: white;
  }

  .baker-left-nav {
    display:none;
  }

  .baker-top-section {
    display: flex;
    flex-direction: column;
  }
}




/* RESPONSIVE *********************************** @media (max-width: 768px) { */
@media (max-width: 768px) { 
    /* More specific styles for components wider than 300px on screens less than 768px */  
  .split-screen {
    flex-direction: column;
    align-items: flex-start;
  }

  .fifth {
    width:100%;
  }
  .third {
    width: 100%;
  }

  .eighty {
    width: 100%;
  }

  .half {
    width: 100%;
  } 
}


/* WHEN collapsing   ***********************************/
/* Admin ***************/

@media (min-width: 768px) and (max-width: 950px) {
  .pm-font {
    font-size: 20px !important;
  }
}

@media (min-width: 950px) and (max-width: 1150px) {
  .pm-font {
    font-size: 30px !important;
  }
}

/* FOR CARD RENDERING ONLY BELOW *********************************** @media (max-width: 768px) { */

/* IF THE SCREEN IS AT LEAST 400 (IE WHEN IT IS BIGGER, DO THIS) *********************************** @media (max-width: 768px) { */

@media (max-width: 400px) {
  .left-nav {
    display:none;
  }
  .admin-container {
    margin-left: 0;
  }
  .header-nav {
    left:0;
    width: 100vw;
  }
  .header-nav-inner {
    margin-left: 40px;
  }
 
  .header-nav-title {
    font-size: 14px;
    flex-grow: 1;
  }
  /*
  .toggle-label {
    display: none;
  }
  */
  .attach-cont {
    flex-direction: column;
  }
  .file-tag {
    max-width: 120px;
  }
}

/* IF THE SCREEN IS AT LEAST 768 (IE WHEN IT IS BIGGER, DO THIS) *********************************** @media (max-width: 768px) { */

@media (min-width: 768px) { 
  .details-container {
    flex-direction: column;
  }
  .details-half {
    width: 100%;
  }
  .step-container {
    margin-top: 70px;
  }
  .i-header {
    padding: 20px;
  }
  .i-form {
    font-size: 14px;
    margin: auto;
    max-width: 500px;
  }
  .i-form-smaller-text {
    font-size: 14px;
  }
}

@media (min-width: 1400px) { 
  .details-container {
    flex-direction: row;
  }
  .details-half {
    width: 50%;
  }
  .step-container {
    margin-top: 70px;
  }
  
}
